import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { Col, Row, Button, Spinner } from "reactstrap";
import { forgotPassword, forgotPasswordOtpGenerate } from "../../dataApi/login";
import toast from "react-hot-toast";
import { useAuth } from "..";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import LoadingOverlayWrapper from "react-loading-overlay-ts";
import Loader from "react-loaders";
import { InputField } from "../../components/input/InputField";

const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

const ForgotPasswordPage = ({ match }) => {
  const { setToken_ } = useAuth();
  const history = useHistory();
  const [loadingOtp, setLoadingOtp] = useState(false);
  const [loadingOtpDone, setLoadingOtpDone] = useState(false);
  const [loading, setLoading] = useState(false);
  const [emailId, setEmailId] = useState(null);
  const [otp, setOtp] = useState(null);
  const [password, setPassword] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState(null);

  const [emailErrorMessage, setEmailErrorMessage] = useState(null);
  const [otpErrorMessage, setOtpErrorMessage] = useState(null);
  const [passwordErrorMessage, setPasswordErrorMessage] = useState(null);
  const [confirmPasswordErrorMessage, setConfirmPasswordErrorMessage] =
    useState(null);

  const handleGenerateOtp = () => {
    if (emailId && emailRegex.test(emailId)) {
      setLoadingOtp(true);
      forgotPasswordOtpGenerate(
        emailId,
        () => {
          setLoadingOtp(false);
          setLoadingOtpDone(true);
        },
        (err) => {
          setLoadingOtp(false);

          toast.error(`Error generaing email reset request ${err}`);
        }
      );
    } else {
      setEmailErrorMessage("Invalid email Id.");
    }
  };

  const handleForgotPassword = () => {
    if (!emailId || !emailRegex.test(emailId)) {
      setEmailErrorMessage("Invalid email Id.");
    } else if (!password) {
      setPasswordErrorMessage("Password is required!");
    } else if (!confirmPassword) {
      setPasswordErrorMessage("Confirm Password is required!");
    } else if (password !== confirmPassword) {
      setPasswordErrorMessage("Password and Confirm password must match!");
    } else {
      setLoading(true);

      forgotPassword(
        { emailId, password, otp },
        () => {
          toast.success("Password reset successful! Navigating to login...");
          setLoading(false);
          setTimeout(() => {
            history.push("/login");
          }, 2000);
        },
        (err) => {
          setLoading(false);
          setOtpErrorMessage(err);
          toast.error(`Error generaing email reset request ${err}`);
        }
      );
    }
  };

  return (
    <Fragment>
      <LoadingOverlayWrapper
        tag="div"
        active={loading}
        styles={{
          overlay: (base) => ({
            ...base,
            background: "#fff",
            opacity: 0.5,
          }),
        }}
        spinner={<Loader color="green" active type="line-scale" />}
      >
        <div className="h-100 bg-plum-plate bg-animation">
          <div className="d-flex h-100 justify-content-center align-items-center">
            <Col md="8" className="mx-auto app-login-box">
              <div className="modal-dialog w-100 mx-auto">
                <div className="modal-content">
                  <div className="modal-body">
                    <div className="h5 modal-title text-center">
                      <h4 className="mt-2">
                        <div className="app-logo-inverse mx-auto mb-3" />

                        <div>Forgot your Password?</div>
                        <span>Please enter email below, to recover it.</span>
                      </h4>
                    </div>

                    <Row>
                      <Col md={12}>
                        <InputField
                          type="email"
                          name="username"
                          label="Email"
                          id="username"
                          placeholder="Email here..."
                          className="form-control"
                          value={emailId}
                          invalid={emailErrorMessage}
                          onChange={(event) => {
                            setEmailId(event.target.value);
                            setLoadingOtp(false);
                            setLoadingOtpDone(false);
                          }}
                        />
                        {emailErrorMessage && (
                          <div className="text-danger">{emailErrorMessage}</div>
                        )}
                      </Col>
                    </Row>

                    {!loadingOtpDone && (
                      <Button
                        color="primary"
                        size="lg"
                        onClick={handleGenerateOtp}
                      >
                        {loadingOtp ? (
                          <p>
                            <Spinner size="sm">Loading...</Spinner> Generating
                          </p>
                        ) : loadingOtpDone ? (
                          "Send!"
                        ) : (
                          "Generate OTP"
                        )}
                      </Button>
                    )}

                    {loadingOtpDone && (
                      <div>
                        <InputField
                          type="otp"
                          name="otp"
                          label="One Time Password"
                          id="otp"
                          placeholder="Enter OTP"
                          className="form-control"
                          value={otp}
                          invalid={otpErrorMessage}
                          onChange={(event) => {
                            setOtp(event.target.value);
                            setOtpErrorMessage(false);
                          }}
                        />

                        {otpErrorMessage && (
                          <div className="text-danger">{otpErrorMessage}</div>
                        )}

                        <InputField
                          type="password"
                          name="password"
                          label="Password"
                          id="password"
                          placeholder="Enter password"
                          className="form-control"
                          value={password}
                          invalid={passwordErrorMessage}
                          onChange={(event) => {
                            setPassword(event.target.value);
                            setPasswordErrorMessage(false);
                          }}
                        />

                        {passwordErrorMessage && (
                          <div className="text-danger">
                            {passwordErrorMessage}
                          </div>
                        )}

                        <InputField
                          type="confirmpassword"
                          name="confirmpassword"
                          label="Confirm password"
                          id="confirmpassword"
                          placeholder="Confirm password"
                          className="form-control"
                          value={confirmPassword}
                          invalid={confirmPasswordErrorMessage}
                          onChange={(event) => {
                            setConfirmPassword(event.target.value);
                            setConfirmPasswordErrorMessage(false);
                          }}
                        />

                        {confirmPasswordErrorMessage && (
                          <div className="text-danger">
                            {confirmPasswordErrorMessage}
                          </div>
                        )}

                        <Button
                          color="primary"
                          size="lg"
                          onClick={handleForgotPassword}
                        >
                          Reset Password
                        </Button>
                      </div>
                    )}

                    <div className="divider" />

                    <div className="modal-footer d-flex justify-content-end">
                      <Link to="/login">
                        <Button color="link" size="lg" loading>
                          Back to login
                        </Button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="text-center text-white opacity-8 mt-3">
                Copyright &copy; Ambica-VaidyaPlus 2024
              </div>
            </Col>
          </div>
        </div>
      </LoadingOverlayWrapper>
    </Fragment>
  );
};

export default ForgotPasswordPage;
