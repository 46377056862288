import { lazy } from "react";
import { Route } from "react-router-dom/cjs/react-router-dom";
const MedicineWindow = lazy(() =>
  import("../../app/patient/medicine/MedicineWindow")
);
const Therapies = lazy(() => import("../../app/therapies"));
const AdminBoard = lazy(() => import("../../app/admin/AdminBoard"));
const Appointments = lazy(() => import("../../app/appointment"));
const Settings = lazy(() => import("../../app/appsettings"));
const WhatsAppWeb = lazy(() => import("../../app/whatsappweb"));
const Billing = lazy(() => import("../../app/billing"));
const Expense = lazy(() => import("../../app/expense"));
const Dashboards = lazy(() => import("../../app/dashboards"));
const Inventory = lazy(() => import("../../app/inventory"));
const Patient = lazy(() => import("../../app/patient"));

export const MainRoutes = [
  {
    name: "dashboards",
    route: <Route path="/dashboard" component={Dashboards} />,
    mainPath: "/dashboard/main",
  },
  {
    name: "patients",
    route: <Route path="/patient" component={Patient} />,
    mainPath: "/patient/list",
  },
  {
    name: "therapies",
    route: <Route path="/therapies" component={Therapies} />,
    mainPath: "/therapies",
  },
  {
    name: "pharmacy",
    route: <Route path="/pharmacy" component={MedicineWindow} />,
    mainPath: "/pharmacy",
  },
  {
    name: "appointments",
    route: <Route path="/appointments" component={Appointments} />,
    mainPath: "/appointments",
  },
  {
    name: "inventory",
    route: <Route path="/inventory" component={Inventory} />,
    mainPath: "/inventory",
  },
  {
    name: "profileSettings",
    route: <Route path="/settings" component={Settings} />,
    mainPath: "/settings/account",
  },
  {
    name: "billing",
    route: <Route path="/billing" component={Billing} />,
    mainPath: "/billing",
  },
  {
    name: "expense",
    route: <Route path="/expense" component={Expense} />,
    mainPath: "/expense",
  },
  {
    name: "whatsapp",
    route: <Route path="/whats-app" component={WhatsAppWeb} />,
    mainPath: "/whats-app",
  },
  {
    name: "admin",
    route: <Route path="/admin" component={AdminBoard} />,
    mainPath: "/admin",
  },
];

export const PatientRoutes = [
  {
    name: "dashboards",
    route: <Route path="/dashboard" component={Dashboards} />,
  },
  { name: "patients", route: <Route path="/patient" component={Patient} /> },
  {
    name: "appointments",
    route: <Route path="/appointment" component={Appointments} />,
  },
  {
    name: "inventory",
    route: <Route path="/inventory" component={Inventory} />,
  },
  {
    name: "appSettings",
    route: <Route path="/settings" component={Settings} />,
  },
  { name: "billing", route: <Route path="/billing" component={Billing} /> },
];

export const SettingsRoutes = [
  {
    name: "dashboards",
    route: <Route path="/dashboard" component={Dashboards} />,
  },
  { name: "patients", route: <Route path="/patient" component={Patient} /> },
  {
    name: "appointments",
    route: <Route path="/appointment" component={Appointments} />,
  },
  {
    name: "inventory",
    route: <Route path="/inventory" component={Inventory} />,
  },
  {
    name: "appSettings",
    route: <Route path="/settings" component={Settings} />,
  },
  { name: "billing", route: <Route path="/billing" component={Billing} /> },
];
