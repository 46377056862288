import React, { Fragment, useState } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import {
  Row,
  Card,
  Col,
  Nav,
  TabContent,
  TabPane,
  NavItem,
  NavLink,
} from "reactstrap";
import PageTitle from "../../layout/main/PageTitle";
import Tabs from "react-responsive-tabs";
import AppSidebar from "../../layout/sidebar";
import AppHeader from "../../layout/header";
import SubscriptionManagement from "./SubscriptionManagement";
import { OPTION_TABLES } from "../../constants";
import OptionsTable from "../appsettings/application/components/OptionsTable";

export default function AdminBoard(props) {
  const [activeOptionTab, setActiveOptionTab] = useState("0");
  const steps = [
    {
      name: "Medicines",
      component: <OptionsTable name={OPTION_TABLES.MEDICINES} />,
    },
    {
      name: "Therapies",
      component: <OptionsTable name={OPTION_TABLES.THERAPIES} />,
    },
    {
      name: "Diagnosis",
      component: <OptionsTable name={OPTION_TABLES.DISEASE} />,
    },
  ];

  return (
    <Fragment>
      <AppHeader />
      <div className="app-main">
        <AppSidebar />
        <div className="app-main__outer">
          <div className="app-main__inner">
            <Fragment>
              <TransitionGroup>
                <CSSTransition
                  component="div"
                  classNames="TabsAnimation"
                  appear={true}
                  timeout={1500}
                  enter={false}
                  exit={false}
                >
                  <div>
                    <PageTitle
                      heading="Admin Board"
                      subheading="Admin management"
                      icon="pe-7s-user icon-gradient bg-tempting-azure"
                    />
                    <div>
                      <Row>
                        <Col>
                          <Card className="main-card"></Card>
                          <Tabs
                            showInkBar={true}
                            unmountOnExit={true}
                            items={[
                              {
                                key: "0",
                                title: (
                                  <h6>
                                    <strong>Subscriptions/Users</strong>
                                  </h6>
                                ),
                                tabClassName: "rounded",
                                getContent: () => <SubscriptionManagement />,
                              },
                              {
                                key: "1",
                                title: (
                                  <h6>
                                    <strong>Options table</strong>
                                  </h6>
                                ),
                                tabClassName: "rounded",
                                getContent: () => (
                                  <div>
                                    <Nav tabs>
                                      {steps.map((s, index) => (
                                        <NavItem>
                                          <NavLink
                                            className={
                                              activeOptionTab === `${index}`
                                                ? "active"
                                                : ""
                                            }
                                            onClick={() =>
                                              setActiveOptionTab(`${index}`)
                                            }
                                          >
                                            {s.name}
                                          </NavLink>
                                        </NavItem>
                                      ))}
                                    </Nav>
                                    <TabContent
                                      activeTab={`${activeOptionTab}`}
                                    >
                                      {steps.map((s, index) => (
                                        <TabPane tabId={`${index}`}>
                                          {s.component}
                                        </TabPane>
                                      ))}
                                    </TabContent>
                                  </div>
                                ),
                              },
                            ]}
                          />
                        </Col>
                      </Row>
                    </div>
                  </div>
                </CSSTransition>
              </TransitionGroup>
            </Fragment>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
