import React, { Fragment, useEffect, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  DropdownToggle,
  DropdownMenu,
  Nav,
  Button,
  NavItem,
  NavLink,
  UncontrolledButtonDropdown,
  Alert,
  UncontrolledAlert,
} from "reactstrap";

import { faAngleDown, faGear } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import avatar1 from "../../../assets/utils/images/avatars/profilePic.jpg";
import {
  useAuth,
  useClinic,
  useTheme,
  useUserProfile,
  useUserProfilePic,
} from "../../../app";
import { Select } from "../../../components/input/SelectField";
import { fetchMyNotifications } from "../../../dataApi/userApi";
import toast from "react-hot-toast";

function UserBox(props) {
  const { setToken_ } = useAuth();
  const [notifications, setNotifications] = useState({});
  const { selectedClinic, setSelectedClinic, clinics } = useClinic();
  const { showThemeSetting, setShowThemeSetting } = useTheme();

  const { userProfile } = useUserProfile();
  const userProfilePic = useUserProfilePic();

  useEffect(() => {
    fetchMyNotifications(
      (data) => {
        setNotifications(data);
      },
      () => {
        toast.error("Failed to load notifications");
      }
    );
  }, []);

  return (
    <Fragment>
      <div className="d-flex flex-wrap">
        {notifications && notifications.SUBSCRIPTION_EXPIRY && (
          <UncontrolledAlert color="error">
            {notifications.SUBSCRIPTION_EXPIRY}
          </UncontrolledAlert>
        )}
        <h4 className="m-2">
          <b>Clinic</b>
        </h4>
        <Select
          className="ms-2"
          placeholder="Select clinic"
          options={[
            { value: null, label: "--Select--" },
            ...((clinics &&
              clinics.map((c) => {
                return { value: JSON.stringify(c), label: c.name };
              })) ||
              []),
          ]}
          value={selectedClinic && JSON.stringify(selectedClinic)}
          onChange={(event) => {
            if (event.target.value === "--Select--") {
              setSelectedClinic(null);
            } else {
              event.target.value &&
                setSelectedClinic(JSON.parse(event.target.value));
            }
          }}
        />
      </div>
      <div className="header-btn-lg pe-0">
        <div className="widget-content p-0">
          <div className="widget-content-wrapper">
            <div className="widget-content-left">
              <UncontrolledButtonDropdown>
                <DropdownToggle color="link" className="p-0">
                  <img
                    width={40}
                    height={40}
                    className="rounded-circle"
                    src={userProfilePic || avatar1}
                    alt=""
                  />
                  <FontAwesomeIcon
                    className="ms-2 opacity-8"
                    icon={faAngleDown}
                  />
                </DropdownToggle>
                <DropdownMenu end className="rm-pointers dropdown-menu-lg">
                  <div className="dropdown-menu-header">
                    <div className="dropdown-menu-header-inner bg-info">
                      <div
                        className="menu-header-image opacity-2"
                        style={{
                          // backgroundImage: "url(" + city3 + ")",
                          backgroundColor: "lightGreen",
                        }}
                      />
                      <div className="menu-header-content text-start">
                        <div className="widget-content p-0">
                          <div className="widget-content-wrapper">
                            <div className="widget-content-left me-3">
                              <img
                                width={40}
                                height={40}
                                className="rounded-circle"
                                src={userProfilePic || avatar1}
                                alt=""
                              />
                            </div>
                            <div className="widget-content-left">
                              <div className="widget-heading">
                                {userProfile?.name}
                              </div>
                              <div className="widget-subheading opacity-8">
                                A short profile description
                              </div>
                            </div>
                            <div className="widget-content-right me-2"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="scroll-area-xs"
                    style={{
                      height: "150px",
                    }}
                  >
                    <PerfectScrollbar>
                      <Nav vertical>
                        {/* <NavItem className="nav-item-header">Activity</NavItem>
                        <NavItem>
                          <NavLink href="#">
                            Chat
                            <div className="ms-auto badge rounded-pill bg-info">
                              8
                            </div>
                          </NavLink>
                        </NavItem> */}
                        {/* <NavItem>
                          <NavLink href="#">Recover Password</NavLink>
                        </NavItem> */}
                        {/* <NavItem className="nav-item-header">
                        My Profile
                        </NavItem> */}
                        <NavItem>
                          <NavLink href="#/profile">
                            My Profile{" "}
                            <FontAwesomeIcon icon={faGear} className="ms-2" />
                            {/* <div className="ms-auto badge bg-success">New</div> */}
                          </NavLink>
                        </NavItem>
                        {/* <NavItem>
                          <Button
                            onClick={() =>
                              setShowThemeSetting(!showThemeSetting)
                            }
                            color="primary"
                            outline
                          >
                            Change Theme
                            <FontAwesomeIcon icon={faGear} className="ms-2" />
                          </Button>
                        </NavItem> */}
                      </Nav>
                    </PerfectScrollbar>
                  </div>
                  {/* <Nav vertical>
                    <NavItem className="nav-item-divider mb-0" />
                  </Nav> */}
                  {/* <div className="grid-menu grid-menu-2col">
                    <Row className="g-0">
                      <Col sm="6">
                        <Button
                          className="btn-icon-vertical btn-transition btn-transition-alt pt-2 pb-2"
                          outline
                          color="warning"
                        >
                          <i className="pe-7s-chat icon-gradient bg-amy-crisp btn-icon-wrapper mb-2">
                            {" "}
                          </i>
                          Message Inbox
                        </Button>
                      </Col>
                      <Col sm="6">
                        <Button
                          className="btn-icon-vertical btn-transition btn-transition-alt pt-2 pb-2"
                          outline
                          color="danger"
                        >
                          <i className="pe-7s-ticket icon-gradient bg-love-kiss btn-icon-wrapper mb-2">
                            {" "}
                          </i>
                          <b>Support Tickets</b>
                        </Button>
                      </Col>
                    </Row>
                  </div> */}
                  <Nav vertical>
                    <NavItem className="nav-item-divider" />
                    <NavItem className="nav-item-btn text-center">
                      {/* <Button size="sm" className="btn-wide" color="primary">
                        Open Messages
                      </Button> */}
                      <Button
                        className="btn-pill btn-shadow btn-shine"
                        color="danger"
                        onClick={() => {
                          localStorage.setItem("token", null);
                          setToken_(null);
                        }}
                      >
                        Logout
                      </Button>
                    </NavItem>
                  </Nav>
                </DropdownMenu>
              </UncontrolledButtonDropdown>
            </div>
            <div className="widget-content-left  ms-3 header-user-info">
              <div className="widget-heading">{userProfile?.name}</div>
              <div className="widget-subheading">{userProfile?.position}</div>
            </div>
            <div className="widget-content-right header-user-info ms-3">
              {/* <Button
                className="btn-shadow p-1"
                size="sm"
                onClick={notify2}
                color="info"
                id="Tooltip-1"
              >
                <IoIosCalendar color="#ffffff" fontSize="20px" />
              </Button> */}
              {/* <UncontrolledTooltip placement="bottom" target={"Tooltip-1"}>
                Click for Toastify Notifications!
              </UncontrolledTooltip> */}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default UserBox;
