import React, { Fragment, useEffect, useState } from "react";
import { Button, Input } from "reactstrap";
import toast from "react-hot-toast";
import { InputField } from "../../../../components/input/InputField";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import {
  fetchOptionTable,
  saveOptionTable,
} from "../../../../dataApi/optionTableApi";
import _ from "lodash";
import { useOptionTable } from "../../..";

function OptionsTable(props) {
  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [data, setData] = useState([]);
  const [optionsType, setOptionsType] = useState(false);
  const push = (item) => {
    setData([...data, item]);
  };

  const remove = (index) => {
    setData(_.remove(data, (d, i) => i != index));
  };

  useEffect(() => {
    if (props.name) {
      fetchOptionTable(
        props.name,
        "ADMIN",
        (data) => {
          if (data) {
            toast.success("Fetched options data");
            if (data[0]) {
              setName(data[0].name);
              setId(data[0]._id);
              setData(data[0].options);
            }
          }
        },
        (err) => {
          toast.error("Option table not found, add data to create one.");
        }
      );
    }
  }, []);

  const handleValueChange = (index, value) => {
    const newData = _.set(data, `[${index}]`, value);
    setData([...newData]);
  };

  const handleSubmit = () => {
    if (saveOptionTable) {
      const payload = id
        ? { _id: id, name: props.name, options: data }
        : { name: props.name, options: data };
      saveOptionTable(
        payload,
        (data) => {
          toast.success(`Saved options data for table : ${props.name}`);
        },
        (err) => {
          toast.error("Error saving options " + err);
        }
      );
    }
  };

  return (
    <Fragment>
      {/* <div className="mb-2">
        <Input type="checkbox" onChange={() => setOptionsType(!optionsType)} />{" "}
        Fetch for all accounts
      </div> */}
      <div className="d-flex flex-wrap">
        {data &&
          data.map((field, index) => (
            <div className="d-flex ms-2">
              <InputField
                type="text"
                id="value"
                size="sm"
                placeholder="Option value"
                value={field}
                onChange={(e) => {
                  handleValueChange(index, e.target.value);
                }}
                appendElement={
                  <Button
                    type="button"
                    color="danger"
                    outline
                    onClick={() => remove(index)}
                  >
                    <FontAwesomeIcon icon={faClose} />
                  </Button>
                }
              />
            </div>
          ))}
      </div>
      <Button type="button" color="success" onClick={() => push("")}>
        Add option
      </Button>
      <div className="divider"></div>
      <Button color="primary" onClick={handleSubmit}>
        Save changes
      </Button>
    </Fragment>
  );
}

export default OptionsTable;
