import { get, put, post, delet } from "../config/api";

export const fetchBills = (patientId, onSuccess, onError) => {
  get("/bill", { params: { patientId: patientId } })
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error.message);
    });
};

export const saveBill = (billData, onSuccess, onError) => {
  if (billData._id) {
    put(`/bill/${billData._id}`, billData)
      .then((response) => {
        onSuccess && onSuccess(response.data);
      })
      .catch((error) => {
        onError && onError(error.message);
      });
  } else {
    post("/bill", billData)
      .then((response) => {
        onSuccess && onSuccess(response.data);
      })
      .catch((error) => {
        if (error.code === "ERR_BAD_REQUEST") {
          onError && onError(error.response.data.message);
        } else {
          onError && onError(error.message);
        }
      });
  }
};

export const deleteBill = (billId, onSuccess, onError) => {
  if (billId) {
    delet(`/bill/${billId}`, billId)
      .then((response) => {
        onSuccess && onSuccess(response.data);
      })
      .catch((error) => {
        onError && onError(error.message);
      });
  }
};

export const sendBillToPatient = (billId, onSuccess, onError) => {
  post(`/patient/sendBill/${billId}`)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error.message);
    });
};
