import { get, post } from "../config/api";

export const loginToApp = (data) => {
  return post("/auth/login", data);
};

export const signUpToApp = (data) => {
  return post("/auth/signup", data);
};

export const forgotPasswordOtpGenerate = (emailId, onSuccess, onError) => {
  get("/auth/forgot-password-otp", { params: { emailId: emailId } })
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error.message);
    });
};

export const forgotPassword = (data, onSuccess, onError) => {
  post("/auth/forgot-password", data)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error.response.data.message);
    });
};
