import { get } from "../config/api";

export const getDashboard = (onSuccess, onError) => {
  get("/dashboard", {
    params: {},
  })
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error.message);
    });
};
