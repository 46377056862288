import { Route, Redirect } from "react-router-dom";
import React, {
  Suspense,
  Fragment,
  useState,
  createContext,
  useContext,
  lazy,
  useMemo,
} from "react";
import Loader from "react-loaders";
import { useAuth, useUserProfile } from "../../app/index";
import LoadingOverlay from "react-loading-overlay-ts";
import { MainRoutes } from "./routes";
import Dashboards from "../../app/dashboards";

const LoadingContext = createContext();
export const useLoading = () => {
  return useContext(LoadingContext);
};

function AppMain() {
  const [loading, setLoading] = useState(false);
  const { userProfile } = useUserProfile();
  const { token } = useAuth();

  const routesToRender = useMemo(() => {
    if (userProfile) {
      if (
        userProfile.position === "ADMIN" ||
        userProfile.position === "SUPER_ADMIN"
      ) {
        return {
          routes: MainRoutes,
          defaultRoute: (
            <Route
              exact
              path="/"
              render={() => <Redirect to={MainRoutes[0].mainPath} />}
            />
          ),
        };
      } else {
        const filteredRoutes = MainRoutes.filter(
          (mr) =>
            mr.name === "profileSettings" || userProfile.accessRights[mr.name]
        );
        return {
          routes: filteredRoutes,
          defaultRoute: (
            <Route
              exact
              path="/"
              render={() => <Redirect to={filteredRoutes[0].mainPath} />}
            />
          ),
        };
      }
    }
    return null;
  }, [userProfile]);

  if (!token) {
    // If not authenticated, redirect to the login page
    return <Redirect to="/login" />;
  }

  return (
    <LoadingContext.Provider value={setLoading}>
      <Fragment>
        <Suspense
          fallback={
            <div className="loader-container">
              <div className="loader-container-inner">
                <div className="text-center">
                  <Loader type="ball-pulse-sync" />
                </div>
                <h6 className="mt-5">Loading, Please wait!</h6>
              </div>
            </div>
          }
        >
          <LoadingOverlay
            tag="div"
            active={loading}
            styles={{
              overlay: (base) => ({
                ...base,
                background: "#fff",
                opacity: 0.5,
              }),
            }}
            spinner={<Loader color="green" active type="line-scale" />}
          >
            {/* <Route path="/dashboard" component={Dashboards} /> */}
            {routesToRender &&
              routesToRender.routes &&
              routesToRender.routes.map((mr) => mr.route)}

            {routesToRender && routesToRender.defaultRoute}
          </LoadingOverlay>
        </Suspense>
        {}
      </Fragment>
    </LoadingContext.Provider>
  );
}

export default AppMain;
