import React, { Fragment, useEffect, useState } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Button,
  Badge,
} from "reactstrap";
import DataTable from "react-data-table-component";
import SearchBox from "../../components/input/SearchBox";
import toast from "react-hot-toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faEdit } from "@fortawesome/free-solid-svg-icons";
import {
  deleteSubscription,
  fetchSubscriptions,
} from "../../dataApi/subscriptionApi";
import NewSubscriptionModal from "./NewSubscriptionModal";
import moment from "moment";
import { useLoading } from "../../layout/main/AppMain";

const STATUS_COLOR = {
  TRAIL: "warning",
  EXPIRED: "danger",
  YEARLY: "info",
  MONTHLY: "primary",
  LIFETIME: "success",
};

export default function SubscriptionManagement(props) {
  const setLoading = useLoading();
  const [searchText, setSearchText] = useState("");
  const [showNewSubscriptionModal, setShowNewSubscriptionModal] =
    useState(false);
  const [selectedSubscription, setSelectedSubscription] = useState(null);
  const [sunscriptionsList, setSunscriptionsList] = useState([]);
  const [filteredSubscriptionList, setFilteredSubscriptionList] = useState([]);

  const getSubscriptions = () => {
    setLoading(true);
    fetchSubscriptions(
      (data) => {
        // toast.success("Fetched all subscriptions.");
        setSunscriptionsList(data);
        setFilteredSubscriptionList(data);
        setLoading(false);
      },
      (errorMessage) => {
        toast.error("Failed to fetch subscriptions: " + errorMessage);
        setSunscriptionsList([]);
        setLoading(false);
      }
    );
  };
  useEffect(() => {
    getSubscriptions();
  }, []);

  useEffect(() => {
    let subscriptions = sunscriptionsList;
    if (searchText) {
      subscriptions = sunscriptionsList.filter(
        (row) =>
          row.name && row.name.toLowerCase().includes(searchText.toLowerCase())
      );
    }
    setFilteredSubscriptionList(subscriptions);
  }, [searchText]);

  const deletSubscription = (subscription) => {
    if (window.confirm("Sure want to delete?")) {
      setLoading(true);
      deleteSubscription(
        subscription.id,
        () => {
          toast.success(`Deleted subscription ${subscription.name}`);
          getSubscriptions();
          setLoading(false);
        },
        (err) => {
          toast.error(`Failed to delete ${err}`);
          setLoading(false);
        }
      );
    }
  };

  const columns = [
    {
      name: "Name",
      id: "name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Start date",
      id: "startDate",
      selector: (row) =>
        row.startDate ? moment(row.startDate).format("DD-MM-YYYY") : "-",
      sortable: true,
    },
    {
      name: "End date",
      id: "endDate",
      selector: (row) =>
        row.endDate ? moment(row.endDate).format("DD-MM-YYYY") : "-",
      sortable: true,
    },
    {
      name: "Status",
      id: "status",
      cell: (row) => (
        <Badge color={row.status ? "success" : "danger"}>
          {row.status ? "ACTIVE" : "IN-ACTIVE"}
        </Badge>
      ),
      sortable: true,
    },
    {
      name: "Type",
      id: "type",
      cell: (row) => (
        <Badge outline color={STATUS_COLOR[row.type]}>
          {row.type}
        </Badge>
      ),
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <div className="w-100 d-flex flex-wrap">
          <Button
            className="mb-2 me-2"
            color="primary"
            outline
            onClick={() => {
              setSelectedSubscription(row);
              setShowNewSubscriptionModal(true);
            }}
          >
            <FontAwesomeIcon icon={faEdit} /> Edit
          </Button>

          <Button
            className="mb-2 me-2"
            color="danger"
            outline
            onClick={() => deletSubscription(row)}
          >
            <FontAwesomeIcon icon={faClose} />
          </Button>
        </div>
      ),
    },
  ];

  return (
    <Fragment>
      {showNewSubscriptionModal && (
        <NewSubscriptionModal
          isOpen={showNewSubscriptionModal}
          toggle={() => {
            setShowNewSubscriptionModal(!showNewSubscriptionModal);
            setSelectedSubscription(null);
          }}
          onSave={getSubscriptions}
          selectedSubscription={selectedSubscription}
        />
      )}
      <TransitionGroup>
        <CSSTransition
          component="div"
          classNames="TabsAnimation"
          appear={true}
          timeout={1500}
          enter={false}
          exit={false}
        >
          <Row>
            <Col md="12">
              <Card className="main-card ">
                <CardHeader className="d-flex text-end">
                  <SearchBox
                    activeSearch
                    hideClose
                    onChange={(event) => setSearchText(event.target.value)}
                    placeholder="Search by name"
                  />
                  {/* <div className="text-center create-patient-btn">
                    <Button
                      className="btn-wide mb-2 me-2 btn-icon btn-icon-right btn-pill"
                      color="primary"
                      onClick={() => setShowNewSubscriptionModal(true)}
                    >
                      Create Subscription
                      <i className="pe-7s-add-user btn-icon-wrapper"> </i>
                    </Button>
                  </div> */}
                </CardHeader>
                <CardBody>
                  <DataTable
                    columns={columns}
                    data={filteredSubscriptionList}
                    // selectableRows
                    pagination
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </CSSTransition>
      </TransitionGroup>
    </Fragment>
  );
}
