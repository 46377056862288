import React, { Fragment } from "react";
import cx from "classnames";
import Navbar from "../nav/NavBar";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import PerfectScrollbar from "react-perfect-scrollbar";
import HeaderLogo from "../applogo";
import { useTheme } from "../../app";

function AppSidebar(props) {
  let { themeOptions, setThemeOptions } = useTheme();

  const toggleMobileSidebar = () => {
    setThemeOptions({
      ...themeOptions,
      enableMobileMenu: !themeOptions.enableMobileMenu,
    });
  };

  return (
    <Fragment>
      <div className="sidebar-mobile-overlay" onClick={toggleMobileSidebar} />
      <TransitionGroup>
        <CSSTransition
          component="div"
          className={cx("app-sidebar", themeOptions.backgroundColor, {
            "sidebar-shadow": themeOptions.enableSidebarShadow,
          })}
          appear={true}
          enter={false}
          exit={false}
          timeout={500}
        >
          <div>
            <HeaderLogo />
            <PerfectScrollbar>
              <div className="app-sidebar__inner">
                <Navbar />
              </div>
            </PerfectScrollbar>
            <div
              className={cx(
                "app-sidebar-bg",
                themeOptions.backgroundImageOpacity
              )}
              style={{
                backgroundImage: themeOptions.enableBackgroundImage
                  ? "url(" + themeOptions.backgroundImage + ")"
                  : null,
              }}
            ></div>
          </div>
        </CSSTransition>
      </TransitionGroup>
    </Fragment>
  );
}

export default AppSidebar;
