import React from "react";
import { Button, ButtonGroup } from "reactstrap";
import cx from "classnames";

import { Field } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const RadioButtonGroup = ({ buttons, field, form, small }) => {
  return buttons.map((option) => (
    <Button
      key={option.value}
      className={cx(
        `btn-transition ${field.value === option.value ? "active" : ""}`,
        {
          "btn-icon-vertical": !small,
          "mb-3": !small,
        }
      )}
      outline
      color={option.btnColor || "primary"}
      onClick={() => form.setFieldValue(field.name, option.value)}
      size="xs"
    >
      <span className="btn-icon-wrapper">
        <FontAwesomeIcon icon={option.icon} className="me-1" />
      </span>
      {!small && (
        <span
          className={`badge bg-${option.value} badge-dot badge-dot-sm badge-dot-inside`}
        >
          {" "}
        </span>
      )}
      {option.label}
    </Button>
  ));
};

function ButtonRadioField(props) {
  return (
    <Field name={props.name}>
      {({ field, form }) => (
        <ButtonGroup style={{ display: "block" }}>
          <RadioButtonGroup
            field={field}
            form={form}
            buttons={props.buttons}
            small={props.small}
          />
        </ButtonGroup>
      )}
    </Field>
  );
}

export default ButtonRadioField;
