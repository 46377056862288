import React, { useEffect } from "react";
import {
  FormGroup,
  Label,
  Input,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import withFormikField from "./FormikField";
import { useOptionTable } from "../../app";

export function Select(props) {
  const { getOptionTable } = useOptionTable();

  const optionTable = React.useMemo(() => {
    return getOptionTable(props.optionTable);
  }, [getOptionTable, props.optionTable]);

  useEffect(() => {
    if (props.onChange && !props.value && optionTable) {
      props.onChange({
        target: {
          name: props.name,
          value: optionTable[0],
        },
      });
    }
  }, []);
  return (
    <div>
      <FormGroup>
        {props.label && <Label for={props.name}>{props.label}</Label>}
        <InputGroup>
          {props.prependElement}
          {props.prependText && (
            <InputGroupText addonType="prepend">
              {props.prependText}
            </InputGroupText>
          )}
          <Input
            type="select"
            name={props.name}
            id={props.id || props.name}
            placeholder={props.placeholder}
            onChange={props.onChange}
            onBlur={props.onBlur}
            invalid={props.invalid}
            value={props.value || ""}
            size={props.size}
            disabled={props.disabled}
            searchable={true}
          >
            {optionTable &&
              optionTable.map((opt) => <option value={opt}>{opt}</option>)}
            {props.options &&
              props.options.map((opt) => (
                <option value={opt.value}>{opt.label}</option>
              ))}
          </Input>
        </InputGroup>
      </FormGroup>
    </div>
  );
}

export default withFormikField(Select);
