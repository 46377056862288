import React, { useEffect, useState } from "react";
import { useLoading } from "../../layout/main/AppMain";
import { useClinic, useDoctors, useUserProfile } from "..";
import toast from "react-hot-toast";
import { deleteBill, saveBill } from "../../dataApi/billApi";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import InputField from "../../components/input/InputField";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCancel, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { ErrorMessage, FieldArray, Formik } from "formik";
import { fetchPatients } from "../../dataApi/patientApi";
import Select from "react-select";

function getTotalAmount(data) {
  if (data) {
    return `₹ ${
      (data.consultationAmount || 0) +
      (data.medicineAmount || 0) +
      (data.therapyAmount || 0) +
      (data.taxAmount || 0)
    }`;
  }
  return `₹ 0.00`;
}

const validate = (values) => {
  const errors = {};

  const totalAmount =
    (values.consultationAmount || 0) +
    (values.taxAmount || 0) +
    (values.medicineAmount || 0) +
    (values.therapyAmount || 0) +
    (values.entries &&
      values.entries.reduce((sum, entry) => sum + (entry.amount || 0), 0));

  if (totalAmount <= 0) {
    errors.totalAmount = "The total amount must be greater than 0";
  }

  if (!values.patient) {
    errors.patient = "Please select patient for bill.";
  }

  if (!values.user) {
    errors.user = "Please select doctor of patient.";
  }

  return errors;
};

export default function NewBillContainer({
  isOpen,
  toggleModal,
  onSubmit,
  initialDataValues,
}) {
  const { selectedClinic } = useClinic();
  const { userProfile } = useUserProfile();
  const setLoading = useLoading();
  const { doctors, refreshDoctors } = useDoctors();
  const [patientsList, setPatientsList] = useState([]);

  const initialValues = {
    billDate: new Date().toISOString().split("T")[0], // Today's date
    patient: null,
    clinic: selectedClinic ? selectedClinic._id || selectedClinic.id : null,
    consultationAmount: 0,
    taxAmount: 0,
    medicineAmount: 0,
    therapyAmount: 0,
    paidAmount: 0,
    paymentStatus: "Pending",
    entries: null,
  };

  useEffect(() => {
    setLoading(true);
    refreshDoctors();

    setLoading(true);
    fetchPatients(
      (data) => {
        setPatientsList(data);
        setLoading(false);
      },
      (errorMessage) => {
        toast.error("Failed to fetch patients: " + errorMessage);
        setPatientsList([]);
        setLoading(false);
      }
    );
  }, []);

  const handleSubmit = (values, { setSubmitting }) => {
    // Here you would typically send the data to your API
    console.log("Submitting new bill:", values);
    setLoading(true);
    setSubmitting(true);
    saveBill(
      { ...values, user: values.user ? values.user : userProfile?._id },
      () => {
        toast.success("Bill created");
        setLoading(false);
        onSubmit();
        setSubmitting(false);
      },
      () => {
        toast.error("Bill creation failed!");
        setLoading(false);
        setSubmitting(false);
      }
    );
  };

  const patientOptions = React.useMemo(() => {
    return patientsList
      ? patientsList.map((p) => {
          return {
            value: p.id,
            // label: `${p.patientName} - ${p.address ? p.address + " - " : ""}${
            //   p.contactNumber ? p.contactNumber + " - " : ""
            // }`,
            label: `${p.patientName} - ${p.contactNumber || "NA"}`,
          };
        })
      : [];
  }, [patientsList]);

  const doctorsOptions = React.useMemo(() => {
    return doctors
      ? doctors.map((d) => {
          return { value: d._id, label: d.name };
        })
      : [];
  }, [doctors]);

  return (
    <Formik
      initialValues={initialDataValues || initialValues}
      validate={validate}
      onSubmit={handleSubmit}
    >
      {({ values, errors, isSubmitting, setFieldValue, submitForm }) => (
        <Modal isOpen={isOpen} fade={false} size="lg">
          <ModalHeader toggle={toggleModal}>
            <span>{initialDataValues ? "Edit bill" : "New bill"}</span>
          </ModalHeader>

          <Form>
            <ModalBody>
              <Row>
                {values._id && (
                  <InputField
                    name="billNumber"
                    label="Bill number"
                    id="billNumber"
                    size="sm"
                    placeholder="Bill number"
                    disabled
                  />
                )}
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label>Select Patient</Label>

                    <Select
                      placeholder="Select Patient"
                      onChange={(value) => {
                        setFieldValue("patient", value.value);
                        setFieldValue("patientName", value.label);
                      }}
                      isSearchable
                      value={patientOptions.find(
                        (op) => op.value === values.patient
                      )}
                      options={patientOptions}
                      isClearable={true}
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label>Select Doctor</Label>

                    <Select
                      placeholder="Select Doctor"
                      value={{ user: values.user, label: values.doctorName }}
                      onChange={(value) => {
                        setFieldValue("user", value.value);
                        setFieldValue("doctorName", value.label);
                      }}
                      isSearchable
                      options={doctorsOptions}
                      isClearable={true}
                      aria-invalid
                    />
                    <ErrorMessage name="user" />
                  </FormGroup>
                </Col>
              </Row>
              <div className="divider" />
              <Row>
                <Col sm={5}>
                  <InputField
                    name="consultationAmount"
                    label="Consultation charges"
                    type="number"
                    id="consultationAmount"
                    size="sm"
                    placeholder="Consultation charges"
                  />

                  <InputField
                    name="therapyAmount"
                    label="Therapy charges"
                    type="number"
                    id="therapyAmount"
                    size="sm"
                    placeholder="Therapy charges"
                  />
                  <InputField
                    name="medicineAmount"
                    label="Medicine charges"
                    type="number"
                    id="medicineAmount"
                    size="sm"
                    placeholder="Medicine charges"
                  />

                  <InputField
                    name="taxAmount"
                    label="Tax/GST"
                    type="number"
                    id="taxAmount"
                    size="sm"
                    placeholder="Tax"
                  />
                  <InputField
                    name="note"
                    label="Note"
                    type="text"
                    id="note"
                    size="sm"
                    placeholder="Note"
                  />
                </Col>
                <Col sm={7}>
                  <FieldArray name="entries">
                    {({ push, remove }) => (
                      <fieldset className="border p-2">
                        <legend className="w-auto float-none">
                          <strong>Bill Entries</strong>
                        </legend>

                        {values.entries &&
                          values.entries.map((_, index) => (
                            <div key={index} className="p-2">
                              <Row>
                                <Col md={6}>
                                  {" "}
                                  <InputGroup>
                                    <InputGroupText>{index + 1}</InputGroupText>
                                    <Input
                                      name={`entries.[${index}].description`}
                                      type="text"
                                      onChange={(e) => {
                                        const { value } = e.target;
                                        setFieldValue(
                                          `entries.[${index}].description`,
                                          value
                                        );
                                      }}
                                      value={values.entries[index].description}
                                      placeholder="Description"
                                    />
                                  </InputGroup>
                                </Col>
                                <Col md={6}>
                                  <InputGroup>
                                    <Input
                                      name={`entries.[${index}].amount`}
                                      type="number"
                                      onChange={(e) => {
                                        const { value } = e.target;
                                        setFieldValue(
                                          `entries.[${index}].amount`,
                                          parseFloat(value)
                                        );
                                      }}
                                      value={values.entries[index].amount}
                                      placeholder="Amount"
                                    />
                                    <Button
                                      color="danger"
                                      onClick={() => remove(index)}
                                      className="me-1"
                                    >
                                      <FontAwesomeIcon icon={faTrash} />
                                    </Button>
                                  </InputGroup>
                                </Col>
                              </Row>
                            </div>
                          ))}

                        <Button
                          type="button"
                          color="primary"
                          outline
                          size="sm"
                          className="ms-2"
                          onClick={() => push({ description: "", amount: 0 })}
                        >
                          Entry <FontAwesomeIcon icon={faPlus} />
                        </Button>
                      </fieldset>
                    )}
                  </FieldArray>
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <FormGroup>
                    <Label for="paymentStatus">Payment Status</Label>
                    <Input
                      type="select"
                      id="paymentStatus"
                      size="sm"
                      name="paymentStatus"
                      placeholder="Payment Status"
                      value={values.paymentStatus}
                      onChange={(e) =>
                        setFieldValue("paymentStatus", e.target.value)
                      }
                      className={
                        values.paymentStatus === "Paid"
                          ? "bg-success"
                          : values.paymentStatus === "Pending"
                          ? "bg-light"
                          : "bg-warning"
                      }
                    >
                      <option value="Pending" className="bg-light">
                        Pending
                      </option>
                      <option value="Partially Paid" className="bg-warning">
                        Partially Paid
                      </option>
                      <option value="Paid" className="bg-success">
                        Paid
                      </option>
                    </Input>
                  </FormGroup>
                </Col>
                <Col sm={6}>
                  <FormGroup>
                    <Label for="totalAmount">Total Amount</Label>
                    <Input
                      id="totalAmount"
                      disabled
                      size="sm"
                      placeholder="Total Amount"
                      value={
                        (values.taxAmount || 0) +
                        (values.consultationAmount || 0) +
                        (values.medicineAmount || 0) +
                        (values.therapyAmount || 0) +
                        ((values.entries &&
                          values.entries.reduce(
                            (acc, entry) => acc + entry.amount,
                            0
                          )) ||
                          0)
                      }
                    />
                    {errors.totalAmount && (
                      <div className="text-danger">{errors.totalAmount}</div>
                    )}
                  </FormGroup>
                </Col>
              </Row>

              {values.paymentStatus == "Partially Paid" && (
                <Row>
                  <Col sm={6}>
                    <InputField
                      name="paidAmount"
                      label="Paid Amount"
                      type="number"
                      id="paidAmount"
                      size="sm"
                      placeholder="Paid Amount"
                    />
                  </Col>
                  <Col sm={6} className="text-danger m-auto">
                    Pending:
                    <strong className="ms-2">{`₹  ${parseFloat(
                      (values.taxAmount || 0) +
                        (values.consultationAmount || 0) +
                        (values.medicineAmount || 0) +
                        (values.therapyAmount || 0) +
                        ((values.entries &&
                          values.entries.reduce(
                            (acc, entry) => acc + entry.amount,
                            0
                          )) ||
                          0) -
                        (values.paidAmount || 0)
                    ).toFixed(2)}`}</strong>
                  </Col>
                </Row>
              )}
            </ModalBody>

            <ModalFooter className="d-flex justify-content-between">
              <div className="d-flex justify-content-between">
                <Button
                  color="danger"
                  className="ms-2"
                  outline
                  onClick={(e) => {
                    e.preventDefault();

                    window.confirm(
                      "Are you sure you want to delete this bill?"
                    ) &&
                      deleteBill(
                        values._id,
                        () => {
                          toast.success("Bill deleted");
                          onSubmit();
                          toggleModal();
                        },
                        () => {
                          toast.error("Failed to delete bill");
                        }
                      );
                  }}
                >
                  Delete <FontAwesomeIcon icon={faCancel} />
                </Button>
              </div>
              <div>
                <Button
                  type="button"
                  onClick={submitForm}
                  disabled={isSubmitting}
                  color="primary"
                >
                  {initialDataValues ? "Save changes" : "Create Bill"}
                </Button>

                <Button
                  type="button"
                  onClick={toggleModal}
                  color="secondary"
                  className="ms-2"
                  outline
                >
                  Cancel
                </Button>
              </div>
            </ModalFooter>
          </Form>
        </Modal>
      )}
    </Formik>
  );
}
