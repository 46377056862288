import { get, put, post, delet } from "../config/api";

export const fetchSubscriptions = (onSuccess, onError) => {
  get("/subscription")
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error.message);
    });
};

export const fetchSubscriptionById = (subscriptionId, onSuccess, onError) => {
  return get(`/subscription/${subscriptionId}`)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error.message);
    });
};

export const saveSubscriptions = (subscriptionData, onSuccess, onError) => {
  if (subscriptionData.id) {
    put(`/subscription/${subscriptionData.id}`, subscriptionData)
      .then((response) => {
        onSuccess && onSuccess(response.data);
      })
      .catch((error) => {
        onError && onError(error.message);
      });
  } else {
    post("/subscription", subscriptionData)
      .then((response) => {
        onSuccess && onSuccess(response.data);
      })
      .catch((error) => {
        onError && onError(error.message);
      });
  }
};

export const deleteSubscription = (subscriptionId, onSuccess, onError) => {
  delet(`/subscription/${subscriptionId}`)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error.message);
    });
};
