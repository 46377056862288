import { get, put, post, delet } from "../config/api";

export const fetchMyClinics = (onSuccess, onError) => {
  get("/clinic")
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error.message);
    });
};

export const saveClinic = (clinicData, onSuccess, onError) => {
  if (clinicData._id) {
    put(`/clinic/${clinicData._id}`, clinicData)
      .then((response) => {
        onSuccess && onSuccess(response.data);
      })
      .catch((error) => {
        onError && onError(error.message);
      });
  } else {
    post("/clinic", clinicData)
      .then((response) => {
        onSuccess && onSuccess(response.data);
      })
      .catch((error) => {
        onError && onError(error.message);
      });
  }
};

export const deleteClinic = (clinicId, onSuccess, onError) => {
  if (clinicId) {
    delet(`/clinic/${clinicId}`, clinicId)
      .then((response) => {
        onSuccess && onSuccess(response.data);
      })
      .catch((error) => {
        onError && onError(error.message);
      });
  }
};
