import { get, put, post } from "../config/api";

export const fetchOptionTable = (name, source, onSuccess, onError) => {
  let url = `/optionTable`;
  if (name) {
    url = `/optionTable/${name}`;
  }
  let params = {};
  if (source) {
    params = { source: source };
  }
  get(url, { params: params })
    .then((response) => {
      if (response && response.data && response.data.length > 0) {
        onSuccess && onSuccess(response.data);
      } else {
        onError && onError("No data found for this option table");
      }
    })
    .catch((error) => {
      onError && onError(error.message);
    });
};

export const saveOptionTable = (optionTableData, onSuccess, onError) => {
  if (optionTableData._id) {
    put(`/optionTable`, optionTableData)
      .then((response) => {
        onSuccess && onSuccess(response.data);
      })
      .catch((error) => {
        onError && onError(error.message);
      });
  } else {
    post("/optionTable", optionTableData)
      .then((response) => {
        onSuccess && onSuccess(response.data);
      })
      .catch((error) => {
        onError && onError(error.message);
      });
  }
};

export const saveOptionTableValue = (optionName, value, onSuccess, onError) => {
  put(`/optionTable/${optionName}?value=${value}`)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error.message);
    });
};
