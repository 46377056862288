import React from "react";
import { FormGroup, InputGroup, InputGroupText, Label, Input as StrapInput } from "reactstrap";
import withFormikField from "./FormikField";
import moment from "moment/moment";

function DateField(props) {
  return (
    <FormGroup>
       <Label for={props.name}>{props.label}</Label>
      <InputGroup>
        {props.prependElement && (
          <InputGroupText addonType="prepend">
            {props.prependElement}
          </InputGroupText>
        )}
        <StrapInput
          type={props.type|| "date"}
          name={props.name}
          id={props.id || props.name}
          placeholder={props.placeholder}
          onChange={(event)=>{
            props.onChange(event);
          }}
          onBlur={props.onBlur}
          invalid={props.invalid}
          value={moment(props.value).format("YYYY-MM-DD")}
        />
      </InputGroup>
    </FormGroup>
  );
}


export default withFormikField(DateField);
