import { get, put, post, delet } from "../config/api";

export const fetchMyMedicineRequests = (clinicId, onSuccess, onError) => {
  // get("/medicine", { params: { clinicId: clinicId } })
  get(`/medicine/${clinicId}`)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error.message);
    });
};

export const saveMedicine = (medicineData, onSuccess, onError) => {
  if (medicineData._id) {
    put(`/medicine/${medicineData._id}`, medicineData)
      .then((response) => {
        onSuccess && onSuccess(response.data);
      })
      .catch((error) => {
        onError && onError(error.message);
      });
  } else {
    post("/medicine", medicineData)
      .then((response) => {
        onSuccess && onSuccess(response.data);
      })
      .catch((error) => {
        onError && onError(error.message);
      });
  }
};

export const deleteMedicine = (medicineId, onSuccess, onError) => {
  if (medicineId) {
    delet(`/medicine/${medicineId}`, medicineId)
      .then((response) => {
        onSuccess && onSuccess(response.data);
      })
      .catch((error) => {
        onError && onError(error.message);
      });
  }
};
