import React, { Fragment, useEffect, useState } from "react";

import PageTitle from "../../../layout/main/PageTitle";
import {
  Button,
  Card,
  CardBody,
  Col,
  ListGroup,
  ListGroupItem,
  Progress,
  Row,
  Table,
} from "reactstrap";
import { XAxis, ResponsiveContainer, BarChart, Bar } from "recharts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPrescription } from "@fortawesome/free-solid-svg-icons";
import CountUp from "react-countup";
import { getDashboard } from "../../../dataApi/dashboardApi";
import { useClinic } from "../..";
import { useLoading } from "../../../layout/main/AppMain";
import { Link } from "react-router-dom/cjs/react-router-dom";
import NewBillContainer from "../../billing/NewBillContainer";

export default function MainDashboard() {
  const [dashboardData, setDashboardData] = useState({});
  const { getClinicName } = useClinic();
  const [selectedBill, setSelectedBill] = useState(null);
  const [showAddBillModal, setShowAddBillModal] = useState(false);

  const setLoading = useLoading();
  useEffect(() => {
    refreshDashboard();
  }, []);

  const refreshDashboard = () => {
    setLoading(true);
    getDashboard(
      (data) => {
        setDashboardData(data);
        setLoading(false);
      },
      () => {
        setLoading(false);
      }
    );
  };
  return (
    <Fragment>
      <div>
        <PageTitle
          heading="Dashboard"
          subheading="All the statistics of the clinic will shown here"
          icon="pe-7s-graph icon-gradient bg-ripe-malin"
        />
        {showAddBillModal && (
          <NewBillContainer
            isOpen={showAddBillModal}
            initialDataValues={selectedBill}
            toggleModal={() => {
              setShowAddBillModal(!showAddBillModal);
            }}
            onSubmit={(bill) => {
              refreshDashboard();
              setShowAddBillModal(!showAddBillModal);
            }}
          />
        )}
        {/* <Tabs
          defaultActiveKey="1"
          renderTabBar={() => <ScrollableInkTabBar />}
          renderTabContent={() => <TabContent />}
        >
          <TabPane tab="This Month" key="1"> */}
        {/* <Row>
                <Col md="6" xl="4">
                  <div className="card mb-3 widget-content">
                    <div className="widget-content-outer">
                      <div className="widget-content-wrapper">
                        <div className="widget-content-left">
                          <div className="widget-heading">Total Patients</div>
                          <div className="widget-subheading">
                            Total attended patients this month
                          </div>
                        </div>
                        <div className="widget-content-right">
                          <div className="widget-numbers text-success">
                            1896
                          </div>
                        </div>
                      </div>
                      <div className="widget-progress-wrapper">
                        <Progress
                          className="progress-bar-sm"
                          color="primary"
                          value="71"
                        />
                        <div className="progress-sub-label">
                          <div className="sub-label-left">
                            Compared to last month
                          </div>
                          <div className="sub-label-right">71%</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col md="6" xl="4">
                  <div className="card mb-3 widget-content">
                    <div className="widget-content-outer">
                      <div className="widget-content-wrapper">
                        <div className="widget-content-left">
                          <div className="widget-heading">Total Panchkarma</div>
                          <div className="widget-subheading">
                            Total Panchkarma therapies till date
                          </div>
                        </div>
                        <div className="widget-content-right">
                          <div className="widget-numbers text-warning">35</div>
                        </div>
                      </div>
                      <div className="widget-progress-wrapper">
                        <Progress
                          className="progress-bar-sm progress-bar-animated-alt"
                          color="danger"
                          value="85"
                        />
                        <div className="progress-sub-label">
                          <div className="sub-label-left">
                            Compared to last month
                          </div>
                          <div className="sub-label-right">85%</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col md="6" xl="4">
                  <div className="card mb-3 widget-content">
                    <div className="widget-content-outer">
                      <div className="widget-content-wrapper">
                        <div className="widget-content-left">
                          <div className="widget-heading">Total Revenue</div>
                          <div className="widget-subheading">
                            Total revenue this month
                          </div>
                        </div>
                        <div className="widget-content-right">
                          <div className="widget-numbers text-danger">
                            ₹ 1,25,450.00
                          </div>
                        </div>
                      </div>
                      <div className="widget-progress-wrapper">
                        <Progress
                          className="progress-bar-sm progress-bar-animated-alt"
                          color="success"
                          value="46"
                        />
                        <div className="progress-sub-label">
                          <div className="sub-label-left">
                            Compared to last month
                          </div>
                          <div className="sub-label-right">46%</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col md="6" xl="4" className="d-xl-none d-lg-block">
                  <div className="card mb-3 widget-content">
                    <div className="widget-content-outer">
                      <div className="widget-content-wrapper">
                        <div className="widget-content-left">
                          <div className="widget-heading">Income</div>
                          <div className="widget-subheading">
                            Expected totals
                          </div>
                        </div>
                        <div className="widget-content-right">
                          <div className="widget-numbers text-focus">$147</div>
                        </div>
                      </div>
                      <div className="widget-progress-wrapper">
                        <Progress
                          className="progress-bar-sm progress-bar-animated-alt"
                          color="info"
                          value="54"
                        />
                        <div className="progress-sub-label">
                          <div className="sub-label-left">Expenses</div>
                          <div className="sub-label-right">100%</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row> */}

        <Row>
          <Col md="6" lg="3">
            <Card className="widget-chart widget-chart2 text-start mb-3 card-btm-border card-shadow-primary border-primary">
              <div className="widget-chat-wrapper-outer">
                <div className="widget-chart-content">
                  <div className="widget-title opacity-5 text-uppercase">
                    New Patients This Month
                  </div>
                  <div className="widget-numbers mt-2 fsize-4 mb-0 w-100">
                    <div className="widget-chart-flex align-items-center">
                      <div>
                        <span className="opacity-10 text-success pe-2">
                          {/* <FontAwesomeIcon icon={faAngleUp} /> */}
                        </span>
                        {dashboardData.newPatientsThisMonth}
                        {/* <small className="opacity-5 ps-1">%</small> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Card>
          </Col>
          <Col md="6" lg="3">
            <Card className="widget-chart widget-chart2 text-start mb-3 card-btm-border card-shadow-primary border-primary">
              <div className="widget-chat-wrapper-outer">
                <div className="widget-chart-content">
                  <div className="widget-title opacity-5 text-uppercase">
                    New Patients This Year
                  </div>
                  <div className="widget-numbers mt-2 fsize-4 mb-0 w-100">
                    <div className="widget-chart-flex align-items-center">
                      <div>
                        <span className="opacity-10 text-success pe-2">
                          {/* <FontAwesomeIcon icon={faAngleUp} /> */}
                        </span>
                        {dashboardData.newPatientsThisYear}
                        {/* <small className="opacity-5 ps-1">%</small> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Card>
          </Col>
          <Col md="6" lg="3">
            <Card className="widget-chart widget-chart2 text-start mb-3 card-btm-border card-shadow-danger border-danger">
              <div className="widget-chat-wrapper-outer">
                <div className="widget-chart-content">
                  <div className="widget-title opacity-5 text-uppercase">
                    Total Revenue (This month)
                  </div>
                  <div className="widget-numbers mt-2 fsize-4 mb-0 w-100">
                    <div className="widget-chart-flex align-items-center">
                      <div>
                        <span className="opacity-10 text-danger pe-2">
                          {/* <FontAwesomeIcon icon={faAngleDown} /> */}
                        </span>
                        {dashboardData.currentTotal}
                        <small className="opacity-5 ps-1">₹</small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Card>
          </Col>
          <Col md="6" lg="3">
            <Card className="widget-chart widget-chart2 text-start mb-3 card-btm-border card-shadow-warning border-warning">
              <div className="widget-chat-wrapper-outer">
                <div className="widget-chart-content">
                  <div className="widget-title opacity-5 text-uppercase">
                    Total Revenue (This year)
                  </div>
                  <div className="widget-numbers mt-2 fsize-4 mb-0 w-100">
                    <div className="widget-chart-flex align-items-center">
                      <div>
                        <small className="opacity-5 pe-1">₹</small>
                        {dashboardData.currentYearTotal}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md="6" xl="4">
            <div className="card mb-3 widget-content">
              <div className="widget-content-outer">
                <div className="widget-content-wrapper">
                  <div className="widget-content-left">
                    <div className="widget-heading">This Month Revenue</div>
                    <div className="widget-subheading"></div>
                  </div>
                  <div className="widget-content-right">
                    <div className="widget-numbers text-success">
                      <CountUp
                        start={0}
                        end={dashboardData.currentTotal}
                        separator=""
                        decimals={0}
                        decimal="."
                        prefix="₹"
                        // suffix="M"
                        duration="20"
                      />
                    </div>
                  </div>
                </div>
                <div className="widget-progress-wrapper">
                  <Progress
                    className="progress-bar-sm"
                    color="primary"
                    value={dashboardData.monthChange}
                  />
                  <div className="progress-sub-label">
                    <div className="sub-label-left">Last Month Growth</div>
                    <div className="sub-label-right">100%</div>
                  </div>
                </div>
              </div>
            </div>

            <div className="card mb-3 widget-content">
              <div className="widget-content-outer">
                <div className="widget-content-wrapper">
                  <div className="widget-content-left">
                    <div className="widget-heading">This Year Revenue</div>
                    <div className="widget-subheading">Revenue streams</div>
                  </div>
                  <div className="widget-content-right">
                    <div className="widget-numbers text-warning">
                      <CountUp
                        start={1}
                        end={dashboardData.currentYearTotal}
                        separator=""
                        decimals={0}
                        decimal="."
                        prefix="₹"
                        // suffix="M"
                        duration="20"
                      />
                    </div>
                  </div>
                </div>
                <div className="widget-progress-wrapper">
                  <Progress
                    className="progress-bar-sm progress-bar-animated-alt"
                    color="danger"
                    value={dashboardData.yearChange}
                  />
                  <div className="progress-sub-label">
                    <div className="sub-label-left">Last Year Growth</div>
                    <div className="sub-label-right">100%</div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col md="6" xl="4">
            {dashboardData.everyMonthlyRevenue && (
              <Card className="mb-3">
                <CardBody>
                  <div className="widget-chart widget-chart2 text-start p-0">
                    <div className="widget-chat-wrapper-outer">
                      <div className="widget-chart-content">
                        <div className="widget-chart-flex">
                          <div className="widget-numbers mt-0">
                            <div className="widget-chart-flex">
                              <div>
                                <small className="opacity-5">Rs</small>
                                <CountUp
                                  start={0}
                                  end={
                                    dashboardData.everyMonthlyRevenue &&
                                    dashboardData.everyMonthlyRevenue.reduce(
                                      (acc, em) => acc + em.totalAmount,
                                      0
                                    )
                                  }
                                  separator=""
                                  decimals={0}
                                  decimal="."
                                  prefix=""
                                  duration="10"
                                />
                              </div>
                              <div className="widget-title ms-2 opacity-5 font-size-lg text-muted">
                                Total revenue
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="widget-chart-wrapper widget-chart-wrapper-lg opacity-10 m-0">
                        <ResponsiveContainer
                          width="100%"
                          height={177}
                          aspect={3.0 / 1.0}
                        >
                          <BarChart
                            data={dashboardData.everyMonthlyRevenue || []}
                          >
                            <XAxis dataKey="_id" />

                            <Bar
                              barGap="12"
                              dataKey="totalAmount"
                              stackId="a"
                              fill="#545cd8"
                            />
                            <Bar
                              barGap="12"
                              dataKey="consultationAmount"
                              fillOpacity=".2"
                              stackId="a"
                              fill="#545cd8"
                            />
                          </BarChart>
                        </ResponsiveContainer>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            )}
          </Col>
        </Row>

        <Row>
          <Col>
            <Card className="main-card mb-3">
              <CardBody>
                <h6 className="text-muted text-uppercase font-size-md opacity-7 mb-3 fw-normal">
                  Today's follow ups
                </h6>
                <Card className="border-light card-border scroll-area-sm">
                  <Table>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Patient Name</th>
                        <th>Contact Number</th>
                        <th>Email</th>
                        <th>Address</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {dashboardData.followupPatients &&
                      dashboardData.followupPatients.length > 0 ? (
                        dashboardData.followupPatients.map((fp, index) => (
                          <tr key={fp._id}>
                            <td>{index + 1}</td>
                            <td>
                              <Link to={`/patient/prescribe/${fp._id}`}>
                                {fp.patientName}
                              </Link>
                            </td>
                            <td>{fp.contactNumber}</td>
                            <td>
                              <span className="text-primary">{fp.emailId}</span>
                            </td>
                            <td>{fp.address}</td>
                            <td>
                              <Link to={`/patient/prescribe/${fp._id}`}>
                                <Button
                                  className="mb-2 me-2"
                                  color="primary"
                                  size="sm"
                                  outline
                                >
                                  <FontAwesomeIcon icon={faPrescription} />
                                </Button>
                              </Link>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="6" className="text-center">
                            No followups today
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </Card>
              </CardBody>
            </Card>{" "}
          </Col>
          <Col>
            <Card className="main-card mb-3">
              <CardBody>
                <h6 className="text-muted text-uppercase font-size-md opacity-7 mb-3 fw-normal">
                  Pending bills
                </h6>
                <Card className="border-light card-border scroll-area-sm">
                  <Table striped hover responsive>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Bill Date</th>
                        <th>Bill Number</th>
                        <th>Amount</th>
                        <th>Clinic</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {dashboardData.pendingBills &&
                        dashboardData.pendingBills.map((fp, index) => (
                          <tr key={fp._id}>
                            <td>{index + 1}</td>
                            <td>{fp.billDate}</td>
                            <td>{fp.billNumber}</td>
                            <td>
                              <strong>
                                ₹{" "}
                                <span className="text-danger">
                                  {(fp.consultationAmount || 0) +
                                    (fp.taxAmount || 0) +
                                    (fp.medicineAmount || 0) +
                                    (fp.therapyAmount || 0) +
                                    (fp.totalAmount || 0) -
                                    (fp.paidAmount || 0)}
                                </span>
                              </strong>
                            </td>
                            <td>{getClinicName(fp.clinic || "")}</td>
                            <td>
                              <Button
                                outline
                                onClick={() => {
                                  setSelectedBill(fp);
                                  setShowAddBillModal(true);
                                }}
                                color="primary"
                                active={
                                  selectedBill && selectedBill._id === fp._id
                                }
                              >
                                Edit
                              </Button>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                </Card>
              </CardBody>
            </Card>{" "}
          </Col>
        </Row>
        {/* </TabPane>
          <TabPane tab="This Year" key="2">
            {/* <CRMDashboard1 /> */}
        {/* </TabPane>
        </Tabs> */}
      </div>
    </Fragment>
  );
}
