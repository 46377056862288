import React, { Fragment, useState } from "react";
import { Slider } from "react-burgers";
import MobileMenu from "../mobilemenu/AppMobileMenu";
import { Link } from "react-router-dom";
import { useTheme } from "../../app";

function HeaderLogo(props) {
  const [active, setActive] = useState(false);
  const { themeOptions, setThemeOptions } = useTheme();

  const toggleEnableClosedSidebar = () => {
    setThemeOptions({
      ...themeOptions,
      enableClosedSidebar: !themeOptions.enableClosedSidebar,
    });
  };

  // const state = {
  //   openLeft: false,
  //   openRight: false,
  //   relativeWidth: false,
  //   width: 280,
  //   noTouchOpen: false,
  //   noTouchClose: false,
  // };

  return (
    <Fragment>
      <div className="app-header__logo">
        <Link to="/patient/list">
          <div className="logo-src" />
        </Link>
        <div className="header__pane ms-auto">
          <div onClick={toggleEnableClosedSidebar}>
            <Slider
              width={26}
              lineHeight={2}
              lineSpacing={5}
              color="#6c757d"
              active={active}
              onClick={() => setActive(!active)}
            />
          </div>
        </div>
      </div>
      <MobileMenu />
    </Fragment>
  );
}
export default HeaderLogo;
