export const DashboardNav = [
  {
    icon: "pe-7s-rocket",
    label: "Dashboards",
    content: [
      // {
      //   icon: "pe-7s-graph2",
      //   label: "Main",
      //   to: "#/dashboard/main",
      // },
      // {
      //   icon: "pe-7s-graph1",
      //   label: "Stats",
      //   to: "#/dashboard/stats",
      // },
    ],
  },
];

export const PatientsNav = [
  {
    icon: "pe-7s-users",
    label: "Patient's List",
    to: "#/patient/list",
  },
  {
    icon: "pe-7s-add-user",
    label: "Add Patient",
    to: "#/patient/new",
  },
];

export const PharmacistNav = [
  {
    icon: "pe-7s-note2",
    label: "Medicine window",
    to: "#/pharmacy",
  },
];

export const ClinicNav = [
  // {
  //   icon: "pe-7s-flag",
  //   label: "Appointments",
  //   to: "#/appointments",
  // },
  {
    icon: "pe-7s-print",
    label: "Billing",
    to: "#/billing",
  },
];

export const InventoryNav = [
  {
    icon: "pe-7s-shopbag",
    label: "Inventory",
    content: [
      {
        icon: "pe-7s-ribbon",
        label: "Dashboard",
        to: "#/inventory/dashboard",
      },
      {
        icon: "pe-7s-download",
        label: "Manage ",
        to: "#/inventory/manage",
      },
    ],
  },
];
export const SettingsNav = [
  {
    icon: "pe-7s-users",
    label: "Users & Access",
    to: "#/settings/users",
  },
  {
    icon: "pe-7s-network",
    label: "My clinics",
    to: "#/settings/clinics",
  },
  // {
  //   icon: "pe-7s-display1",
  //   label: "Application settings",
  //   to: "#/settings/application",
  // },
];

export const AdminNav = [
  {
    icon: "pe-7s-tools",
    label: "Admin board",
    to: "#/admin",
  },
];

export const BillingNav = [
  {
    icon: "pe-7s-notebook",
    label: "Billing",
    content: [
      {
        icon: "pe-7s-credit",
        label: "Patient bill",
        to: "#/billing/patient/new",
      },
      {
        icon: "pe-7s-cash",
        label: "Previous bills",
        to: "#/billing/bills",
      },
      {
        icon: "pe-7s-calculator",
        label: "Audit",
        to: "#/billing/audit",
      },
      {
        icon: "pe-7s-browser",
        label: "Spend management",
        to: "#/billing/spend/management",
      },
    ],
  },
];
