import React from "react";
import { Field } from "formik";
import { FormFeedback } from "reactstrap";

const withFormikField = (WrappedComponent) => {
  const FormikFieldHOC = (props) => {
    return (
      <Field name={props.name} type={props.type}>
        {({ field, form, meta }) => (
          <div>
            <WrappedComponent {...field} {...props} form={form} invalid={meta.touched &&meta.error} />
            {meta.touched && meta.error && (
              <FormFeedback style={{ display: "block" }}>
                {meta.error}
              </FormFeedback>
            )}
          </div>
        )}
      </Field>
    );
  };

  return FormikFieldHOC;
};

export default withFormikField;
