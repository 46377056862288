import React from "react";
import {
  Button,
  Col,
  Form,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import * as Yup from "yup";
import InputField from "../../components/input/InputField";
import { Formik } from "formik";
import { useLoading } from "../../layout/main/AppMain";
import toast from "react-hot-toast";
import { saveSubscriptions } from "../../dataApi/subscriptionApi";
import SelectField from "../../components/input/SelectField";
import DateField from "../../components/input/DateField";
import {
  faCircleCheck,
  faCircleXmark,
} from "@fortawesome/free-solid-svg-icons";
import ButtonRadioField from "../../components/input/ButtonRadioField";

const validationSchema = Yup.object({
  name: Yup.string().required("Name of report is required!"),
  status: Yup.string().required("Status is required"),
  type: Yup.string().required("Type is required"),
  startDate: Yup.string().required("Start date is required"),
  endDate: Yup.string().required("End date is required"),
  numberOfUsers: Yup.string().required("Number of users is required"),
  // emailId: Yup.string().required("Email Id is required").email(),
  // password: Yup.string().required("Password is required"),
});

const radioButtonOptions = [
  {
    label: "Active",
    value: true,
    icon: faCircleCheck,
    btnColor: "success",
  },
  {
    label: "In-active",
    value: false,
    icon: faCircleXmark,
    btnColor: "danger",
  },
];

export default function NewSubscriptionModal(props) {
  const setLoading = useLoading();

  const handleSaveSubscription = (values) => {
    setLoading(true);
    saveSubscriptions(
      {
        ...values,
        status: values.status === "true" || values.status === true,
      },
      (res) => {
        setLoading(false);
        toast.success(`Saved!`);
        props.toggle();
        props.onSave();
      },
      (err) => {
        toast.error(`Error saving subscription ${err}`);
        setLoading(false);
      }
    );
  };

  return (
    <Modal
      isOpen={props.isOpen}
      size="md"
      toggle={props.toggle}
      className="shadow-none"
      scrollable
      centered
      unmountOnClose
    >
      <ModalHeader toggle={props.toggle}>New Subscription</ModalHeader>
      <ModalBody>
        <Formik
          initialValues={
            props.selectedSubscription || {
              name: null,
              status: false,
              startDate: null,
              endDate: null,
              type: null,
              numberOfUsers: null,
              emailId: null,
              password: null,
            }
          }
          validationSchema={validationSchema}
          onSubmit={handleSaveSubscription}
        >
          {(formik) => (
            <Form>
              <Row>
                <Col>
                  <InputField
                    type="text"
                    name="name"
                    id="name"
                    label="Subscription name"
                    placeholder="Enter subscription name here"
                  />
                </Col>
                <Col>
                  <SelectField
                    name="type"
                    id="type"
                    label="Subscription type"
                    placeholder="Select subscription type"
                    options={[
                      { value: "", label: "" },
                      { value: "TRIAL", label: "TRIAL" },
                      { value: "YEARLY", label: "YEARLY" },
                      { value: "MONTHLY", label: "MONTHLY" },
                      { value: "LIFETIME", label: "LIFETIME" },
                      { value: "EXPIRED", label: "EXPIRED" },
                    ]}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <DateField
                    name="startDate"
                    id="startDate"
                    label="Start date"
                    placeholder="Start date"
                  />
                </Col>
                <Col>
                  <DateField
                    name="endDate"
                    id="endDate"
                    label="End date"
                    placeholder="End date"
                  />
                </Col>
              </Row>
              <Row>
                <Col className="">
                  <div className="mb-2">Subscription status</div>
                  <ButtonRadioField
                    name="status"
                    className="mt-2"
                    small={true}
                    buttons={radioButtonOptions}
                    value
                  />
                </Col>

                <Col>
                  <InputField
                    type="number"
                    name="numberOfUsers"
                    id="numberOfUsers"
                    label="Number of users"
                    placeholder="Enter number of users"
                  />
                </Col>
              </Row>
              <div className="divider" />
              {/* <fieldset className="border p-2">
                <legend className="w-auto float-none">
                  Admin user details
                </legend>

                <Row>
                  <Col>
                    <InputField
                      type="email"
                      name="emailId"
                      id="emailId"
                      label="Email ID"
                      placeholder="Enter admin email Id"
                    />
                  </Col>
                  <Col>
                    <InputField
                      type="password"
                      name="password"
                      id="password"
                      label="Password"
                      placeholder="Enter admin password"
                    />
                  </Col>
                </Row>
              </fieldset> */}
              <Button
                color="primary"
                onClick={formik.submitForm}
                className="mt-2"
              >
                Save & Create
              </Button>
            </Form>
          )}
        </Formik>
      </ModalBody>
      {props.footer && <ModalFooter>{props.footer}</ModalFooter>}
    </Modal>
  );
}
