import { get, put, post, delet } from "../config/api";

export const fetchPatients = (onSuccess, onError) => {
  get("/patient")
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error.message);
    });
};

export const fetchPatientById = (patientId, onSuccess, onError) => {
  return get(`/patient/${patientId}`)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error.message);
    });
};

export const savePatient = (patientData, onSuccess, onError) => {
  if (patientData.id) {
    put(`/patient/${patientData.id}`, patientData)
      .then((response) => {
        onSuccess && onSuccess(response.data);
      })
      .catch((error) => {
        onError && onError(error.message);
      });
  } else {
    post("/patient", patientData)
      .then((response) => {
        onSuccess && onSuccess(response.data);
      })
      .catch((error) => {
        onError && onError(error.message);
      });
  }
};

export const deletePatient = (patientId, onSuccess, onError) => {
  delet(`/patient/${patientId}`)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error.message);
    });
};

export const sendPrescriptionToPatient = (
  patientId,
  date,
  onSuccess,
  onError
) => {
  post(`/patient/sendPrescription/${patientId}/${date}`)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error.message);
    });
};
