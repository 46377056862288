import React, { Fragment, useState } from "react";
import { Input } from "reactstrap";

import cx from "classnames";

function SearchBox(props) {
  const [activeSearch, setActiveSearch] = useState(props.activeSearch || false);

  return (
    <Fragment>
      <div
        className={cx("search-wrapper", {
          active: activeSearch,
        })}
      >
        <div className="input-holder">
          <Input
          plaintext
            type="search"
            className="search-input"
            placeholder={props.placeholder || "Type to search"}
            onChange={props.onChange}
            value={props.value}
          />
          <button
            onClick={() => setActiveSearch(!activeSearch)}
            className="search-icon"
          >
            <span />
          </button>
        </div>
        {!props.hideClose && 
        <button
          onClick={() => setActiveSearch(!activeSearch)}
          className="btn-close"
        />
        }
      </div>
    </Fragment>
  );
}

export default SearchBox;
