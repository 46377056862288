import React, { Fragment, useEffect } from "react";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { Select } from "../../components/input/SelectField";
import { useAuth, useClinic } from "..";
import { fetchMyClinics } from "../../dataApi/clinicApi";
import toast from "react-hot-toast";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLongArrowAltRight } from "@fortawesome/free-solid-svg-icons";

export const CommonComponents = ({ match }) => {
  const { selectedClinic, setSelectedClinic, clinics, setClinics } =
    useClinic();
  const { token } = useAuth();
  const history = useHistory();

  useEffect(() => {
    fetchMyClinics(
      (data) => {
        setClinics(data);
      },
      (errorMessage) => {
        toast.error("Failed to fetch clinics: " + errorMessage);
      }
    );
  }, []);

  return (
    <Fragment>
      className="shadow-none"
      <Modal
        size="sm"
        isOpen={token && !selectedClinic}
        centered
        className="shadow-none"
      >
        <ModalHeader>Select clinic</ModalHeader>
        <ModalBody>
          {clinics && clinics.length > 0 ? (
            <Select
              className="mx-2"
              placeholder="Select clinic"
              options={[
                { value: null, label: "--Select--" },
                ...((clinics &&
                  clinics.map((c) => {
                    return { value: JSON.stringify(c), label: c.name };
                  })) ||
                  []),
              ]}
              value={JSON.stringify(selectedClinic)}
              onChange={(event) =>
                setSelectedClinic(JSON.parse(event.target.value))
              }
            />
          ) : (
            <div className="text-danger">
              No clinics added, Please add clinic first!
              <Button
                className="mt-2"
                color="primary"
                outline
                onClick={() => history.push("/settings/clinics")}
              >
                Go to Clinic settings{" "}
                <FontAwesomeIcon icon={faLongArrowAltRight} />
              </Button>
            </div>
          )}
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

export default CommonComponents;
