import { Route, Redirect } from "react-router-dom";
import React, {
  Suspense,
  Fragment,
  useState,
  createContext,
  useContext,
  lazy,
} from "react";
import Loader from "react-loaders";
import { useAuth, useUserProfile } from "../../app/index";
import LoadingOverlay from "react-loading-overlay-ts";
import { MainRoutes } from "./routes";

const LoadingContext = createContext();
export const useLoading = () => {
  return useContext(LoadingContext);
};

const Profile = lazy(() => import("../../app/profile"));

function AppMain() {
  const [loading, setLoading] = useState(false);
  const { userProfile } = useUserProfile();
  const { token } = useAuth();

  if (!token) {
    // If not authenticated, redirect to the login page
    return <Redirect to="/login" />;
  }

  const getRoutes = () => {
    if (userProfile) {
      if (
        userProfile.position === "ADMIN" ||
        userProfile.position === "SUPER_ADMIN"
      ) {
        return MainRoutes.map((mr) => mr.route);
      } else {
        return MainRoutes.filter((mr) => userProfile.accessRights[mr.name]).map(
          (mr) => mr.route
        );
      }
    }
    return null;
  };
  return (
    <LoadingContext.Provider value={setLoading}>
      <Fragment>
        <Suspense
          fallback={
            <div className="loader-container">
              <div className="loader-container-inner">
                <div className="text-center">
                  <Loader type="ball-pulse-sync" />
                </div>
                <h6 className="mt-5">Loading, Please wait!</h6>
              </div>
            </div>
          }
        >
          <LoadingOverlay
            tag="div"
            active={loading}
            styles={{
              overlay: (base) => ({
                ...base,
                background: "#fff",
                opacity: 0.5,
              }),
            }}
            spinner={<Loader color="green" active type="line-scale" />}
          >
            {/* <Route path="/dashboard" component={Dashboards} /> */}
            {getRoutes()}
            <Route path="/profile" component={Profile} />
          </LoadingOverlay>
        </Suspense>

        <Route exact path="/" render={() => <Redirect to="/patient/list" />} />
      </Fragment>
    </LoadingContext.Provider>
  );
}

export default AppMain;
